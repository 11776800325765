<template>
  <AppRow permission="GAMES_ADMINISTRATION">
    <template v-slot>
      <ApolloQuery
        :query="require('../graphql/queries/getBetsMenu.gql')"
      >
        <template
          v-slot="{ result: { error: menuError, data: menuData }, isLoading: menuIsLoading }"
        >
          <AppNotification
            v-if="menuError"
            :message="menuError.message"
          />
          <ApolloQuery
            :query="require('../graphql/queries/adminBetsEventsBetCount.gql')"
            :variables="{
              type: eventType,
              skip: (betsPageIndex - 1) * betsItemsPerPage,
              limit: betsItemsPerPage,
              status,
              gameId: gameId ? +gameId : null,
            }"
          >
            <template
              v-slot="{ result: { error: betsError, data: betsData }, isLoading: betsIsLoading, query }"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <v-select
                    v-model="eventType"
                    :items="eventTypeList"
                    background-color="#464646"
                    class="rounded-lg"
                    flat
                    hide-details="auto"
                    item-text="text"
                    item-value="key"
                    label="Тип матча"
                    solo
                    @change="resetGameIdAndRefetch(query)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <v-select
                    v-model="status"
                    :items="statusList"
                    background-color="#464646"
                    class="rounded-lg"
                    flat
                    hide-details="auto"
                    item-text="text"
                    item-value="key"
                    label="Статус"
                    solo
                    @change="firstPage(query)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <v-select
                    v-if="menuData"
                    v-model="gameId"
                    :items="menuData.getBetsMenu[eventType.toLowerCase()].map((item) => ({ text: item.name, key: item.id }))"
                    background-color="#464646"
                    class="rounded-lg"
                    clearable
                    flat
                    hide-details="auto"
                    item-text="text"
                    item-value="key"
                    label="Игра"
                    solo
                    @change="firstPage(query)"
                  />
                </v-col>
              </v-row>
              <v-col>
                <!-- Error -->
                <AppNotification
                  v-if="betsError"
                  :message="betsError.message"
                />
                <!-- Result -->
                <v-card
                  v-else-if="betsData || betsIsLoading === 1 || menuIsLoading === 1"
                  :loading="betsIsLoading === 1 || menuIsLoading === 1"
                  class="px-4 pb-3"
                  flat
                >
                  <template v-if="betsData && menuData">
                    <v-card-title>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          Матчи: {{ betsData.adminBetsEventsBetCount.totalCount }}
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <ApolloMutation
                      :mutation="require('../graphql/mutations/adminBetsModerateEvent.gql')"
                      :refetch-queries="refetchQueriesAfterMyMutation"
                      :variables="{
                        userId,
                        reason,
                      }"
                    >
                      <template v-slot="{ mutate, loading, error: removeError }">
                        <v-dialog
                          v-model="dialog"
                          max-width="400px"
                          persistent
                        >
                          <v-card :loading="loading">
                            <v-card-title>Одобрить матч</v-card-title>

                            <v-card-text>
                              <v-text-field
                                v-model.number="reductionFactor"
                                hide-details="auto"
                                label="Reduction Factor"
                              />
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer />
                              <v-btn
                                color="primary"
                                text
                                @click.stop="mutate({
                                  variables: {
                                    eventId,
                                    status: 'ACTIVE',
                                    reductionFactor,
                                  }
                                })"
                              >
                                Одобрить
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <AppNotification
                          v-if="removeError"
                          :icon-disabled="true"
                          :message="removeError.message"
                        />
                      </template>
                    </ApolloMutation>
                    <v-data-table
                      :headers="headers"
                      :items="betsData.adminBetsEventsBetCount.events"
                      :items-per-page="betsItemsPerPage"
                      disable-sort
                      hide-default-footer
                    >
                      <template v-slot:[`item.game`]="{ item }">
                        {{ menuData.getBetsMenu[eventType.toLowerCase()].find((r) => r.id == item.game).name }}
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <ApolloMutation
                          :mutation="require('../graphql/mutations/adminBetsModerateEvent.gql')"
                          :refetch-queries="refetchQueriesAfterMyMutation"
                        >
                          <template v-slot="{ mutate, loading, error: acceptOrDenyError }">
                            <v-btn
                              v-if="status !== 'CANCEL'"
                              class="text-none ma-1"
                              depressed
                              height="36"
                              x-small
                              style="margin-top: .7rem !important"
                              @click.stop="mutate({
                                variables: {
                                  eventId: item.id,
                                  status: 'CANCEL',
                                }
                              })"
                            >
                              Отклонить
                            </v-btn>
                            <v-btn
                              v-if="status !== 'ACTIVE'"
                              class="text-none ma-1"
                              color="primary"
                              depressed
                              height="36"
                              x-small
                              style="margin-top: .7rem !important"
                              @click.stop="showDialog(item.id)"
                            >
                              Одобрить
                            </v-btn>
                            <AppNotification
                              v-if="acceptOrDenyError"
                              :icon-disabled="true"
                              :message="acceptOrDenyError.message"
                            />
                          </template>
                        </ApolloMutation>
                      </template>
                    </v-data-table>
                    <v-divider />

                    <div class="text-center pt-3">
                      <v-pagination
                        v-model="betsPageIndex"
                        :length="Math.ceil(betsData.adminBetsEventsBetCount.totalCount / betsItemsPerPage)"
                        :total-visible="9"
                      />

                      <v-select
                        v-model="betsItemsPerPage"
                        :items="[5, 10, 15]"
                        class="pa-4 pt-5"
                        label="Строк на странице"
                        @change="betsItemsPerPage"
                      />
                    </div>
                  </template>
                </v-card>

                <!-- No result -->
                <AppNotification
                  v-else
                  message="Нет результата :("
                />
              </v-col>
            </template>
          </ApolloQuery>
        </template>
      </ApolloQuery>
    </template>
  </AppRow>
</template>
<script>
import AppRow from '@/components/AppRow.vue';
import AppNotification from '@/components/AppNotification.vue';
import adminBetsEventsBetCount from '@/graphql/queries/adminBetsEventsBetCount.gql';

export default {
  components: {
    AppNotification,
    AppRow,
  },
  data() {
    return {
      editedIndex: -1,
      headers: [
        { text: 'Название:', value: 'title', class: 'white--text text-subtitle-2' },
        { text: 'Игра:', value: 'game', class: 'white--text text-subtitle-2' },
        { text: 'Турнир:', value: 'tnm', class: 'white--text text-subtitle-2' },
        { text: 'Страна:', value: 'country', class: 'white--text text-subtitle-2' },
        { text: 'Кол-во ставок:', value: 'betsCount', class: 'white--text text-subtitle-2' },
        { text: 'Дата:', value: 't', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
      eventType: 'LIVE',
      gameId: null,
      eventTypeList: [
        { text: 'Лайв', key: 'LIVE' },
        { text: 'Линия', key: 'LINE' },
      ],
      status: null,
      statusList: [
        { text: 'В модерации', key: null },
        { text: 'Активные', key: 'ACTIVE' },
        { text: 'Отмененные', key: 'CANCEL' },
      ],
      betsPageIndex: 1,
      betsItemsPerPage: 5,
      reductionFactor: null,
      dialog: false,
      eventId: null,
    };
  },
  methods: {
    refetchQueriesAfterMyMutation() {
      this.dialog = false;
      return [{
        query: adminBetsEventsBetCount,
        variables: {
          type: this.eventType,
          skip: (this.betsPageIndex - 1) * this.betsItemsPerPage,
          limit: this.betsItemsPerPage,
          status: this.status,
          gameId: this.gameId ? +this.gameId : null,
        },
        fetchPolicy: 'no-cache',
      }];
    },
    showDialog(eventId) {
      this.reductionFactor = null;
      this.eventId = eventId;
      this.dialog = true;
    },
    resetGameIdAndRefetch(query) {
      this.gameId = null;
      this.firstPage(query);
    },
    firstPage(query) {
      setTimeout(() => query.refetch(), 0);
      this.betsPageIndex = 1;
    },
  },
};
</script>
